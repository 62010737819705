import React from "react"
import Layout from "../../components/Layout/Layout"
import News from "../../components/Sections/News";



export default function Program() {
    return (
        <Layout>
            <div className={"pt-5"}>
                <News/>
            </div>
        </Layout>
    );
}